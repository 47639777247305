.AccountsPage :global {
    @apply w-full h-full;

    .logoSection {
        @apply text-left h-fit p-4 mt-2;
    }

    .pageTitleSection {
        @apply lg:text-right lg:px-10 lg:-mt-16;
    }

    @media screen and (max-width: 767px) {
        .pageTitleSection {
            @apply w-full px-10 h-[200px] text-center flex flex-col justify-center;
        }

        .logoSection {
            @apply hidden;
        }
    }
}